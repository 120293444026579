|<<template>
  <div class="historyQuery-container">
        <back></back>
        <div class="item" v-for="(item, index) in list" :key="index">
            <div class="left">
                <span v-for="(ite,key,index) in item.condition.typeLittle" :key="index" > {{key}} <span >:</span> </span>
                <ul>
                    <li v-for="(ite,key,index) in item.condition.typeLittle" :key="index" v-if="index<1">
                       {{ite.keyword}}
                        <div class="shu"></div>
                    </li>
                </ul>
            </div>
            <span class="right">
                    {{item.createTime}}
            </span>
        </div>
        <div class="pagination">
            <el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize" @current-change="changePage" :total="shopPage.totalRows">
            </el-pagination>
            <div class="total">共{{ shopPage.totalRows }}条/{{ shopPage.totalPage }}页</div>
            <div class="jump">
                跳转到
                <input type="number" v-model="shopNum">
                页
            </div>
            <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goShopPage">前往</el-button>
        </div>
  </div>
</template>

<script>
import back from "../../components/square/back.vue";
export default {
    name: "historyQuery",
    components: {
        back
    },
    data() {
        return {
            shopNum: "", //店铺的页数
            shopPage: {}, //需求总页数
            shopSelect: {
                //店铺的筛选参数
                addr: "",
                type: 0,
                moreYear: 0,
                lessYear: 0,
                pageNo: 1,
                classifyId: 0, //分类id
                pageSize: 24,
            },
            list: [],
            listName: []
        };

    },
    created() {
        this.init()
        // this.getShopList()
    },
    methods: {
        golink(ind) {
            console.log(ind.length);

        },
        toInfo(val) {
            console.log(val);
            sessionStorage.setItem('hisInfo', val.data)
            this.$router.push({ path: `/historyInfo` })
        },
        goShopPage() {
            this.shopSelect.pageNo = this.shopNum;
            this.init();
        },
        changePage(num) {
            this.shopSelect.pageNo = num;
            this.init();
        },
        // getShopList() {
        //     this.$util.post("/dataSquare/list", this.shopSelect).then((res) => {
        //         console.log(res);

        //         this.goodShopDefList = res.data;
        //     });
        // },
        init() {
            this.$util.post("/dataSquare/list", this.shopSelect).then((res) => {
                this.listName = res.data
                this.shopPage = res.pager;
                res.data.forEach(el => {
                    el.createTime
                    el.condition = JSON.parse(el.condition)
                    el.data = JSON.parse(el.data)
                    // el.condition.typeLittle = Object.keys(el.condition.typeLittle)
                })
                // this.listName);
                console.log(res);
                this.list = res.data

            });
        }
    },
}
</script>

<style lang="scss" scoped>
.historyQuery-container {
    // height: 1500px;
    background-color: #ffffff;

    .item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        height: 80px;
        background: #f8f8f8;
        margin: 30px auto 0;
        padding: 0 30px;

        .left {
            font-size: 18px;
            font-weight: 400;
            color: #222222;
        }
        .right {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
        }
        ul {
            display: inline-flex;
        }
        li {
            display: flex;
            list-style: none;
            align-items: center;
        }
        li:last-child div {
            display: none;
        }
        .shu {
            width: 1px;
            height: 15px;
            background-color: #000000;
            margin: 0 5px;
        }
    }
}
.pagination {
    width: 1200px;
    margin: 43px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>